module.exports = [{
      plugin: require('/opt/build/repo/frontend/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"iny2nka"}},
    },{
      plugin: require('/opt/build/repo/frontend/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N6ZMQB2","includeInDevelopment":false},
    },{
      plugin: require('/opt/build/repo/frontend/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/frontend/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
